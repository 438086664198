<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,

  mounted() {
    this.renderChart(
      {
        labels: [
          "定存",
          "債券",
          "股市大盤",
          "兔子理財",
        ],
        datasets: [
          {
            label: "年利率",
            backgroundColor: [
                'rgba(54, 162, 235, 0.3)',
                'rgba(54, 162, 235, 0.3)',
                'rgba(54, 162, 235, 0.3)',
                'rgba(255, 99, 99, 0.4)',
            ],
            borderColor: [
                'rgb(54, 162, 235)',
                'rgb(54, 162, 235)',
                'rgb(54, 162, 235)',
                'rgb(255, 99, 99)',
            ],
            borderWidth: 1,
            data: [1.5, 3, 8, 25]
          }
        ],
      },
      
      // options
        {  
            tooltips: {
                enabled: false
            },
            responsive: true, 
            maintainAspectRatio: false,
            // "hover": {
            //     "animationDuration": 0
            // },
            "animation": {
                "onProgress": function() {
                    var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function(dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function(bar, index) {
                            var data = dataset.data[index];
                            // Change font size of Canvas without knowing font family
                            if (data == 25) {
                                var newSize = '30px';
                                var fontArgs = ctx.font.split(' ');
                                ctx.font = 'bold ' + newSize + ' ' + fontArgs[fontArgs.length - 1];
                            } else {
                                var newSize = '20px';
                                var fontArgs = ctx.font.split(' ');
                                ctx.font = newSize + ' ' + fontArgs[fontArgs.length - 1];
                            }
                            
                           
                            ctx.fillText(data + ' %', bar._model.x, bar._model.y - 5);
                        });
                    });
                }
            },
            "legend": {
                "display": false,
                "labels": {
                    "fontSize": 20,
                }
            },
            title: {
                    display: true,
                    text: '年報酬率',
                    fontSize: 20,
                    fontColor: 'black',
                },
            scales: {
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        max: 35
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontSize: 20
                    }
                }]
            }
        }
    );
  }
};
</script>
