






























































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import GainChart from "../components/GainChart.vue";

Vue.component('GainChart', GainChart);

@Component
export default class Home extends Vue {
  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  get totalAsset() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.total_asset
    }
  }

  get activePlanRaisedPercentage() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.active_plan_AUM / 100000 * 100;
    }
  }

  get activePlanCurrentAUM() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.active_plan_AUM;
    }
  }

  get historyUSDTFixed() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_fixed;
    }
  }

  get historyUSDTHigh() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_high;
    }
  }

  get historyUSDTStable() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_stable;
    }
  }

  get historyUSDDStable() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDD_stable;
    }
  }

  get historyTWDStable() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_TWD_stable;
    }
  }

  get historyUSDTActive() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_active;
    }
  }

  get historyUSDTSun() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_sun;
    }
  }

  get historyUSDTMeteor() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_meteor;
    }
  }

  get historyBTCStable() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_BTC_stable;
    }
  }

  get historyETHStable() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_ETH_stable;
    }
  }

  get historyBNBStable() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_BNB_stable;
    }
  }

  get averageAPY() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_sun*0.15 + constants.APR_USDT_meteor*0.85;
    }
  }

  get gainUSDTActive() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.Gain_USDT_active;
    }
  }  

  get gainedAsset() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.last_month_gain;
    }
  }

  get version() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.version;
    }
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}

